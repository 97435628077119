<template>
  <div>
    <v-card class="nospace">
      <v-card-title class="nospace bg-clr">
        <v-layout align-center> </v-layout>
      </v-card-title>
      <v-card-text class="nospace">
        <v-data-table
          id="paymentsTable"
          style="padding-top: 20px"
          class="word___Break"
          no-data-text="No Assignments given so far"
          hide-actions
          :headers="headers"
          :items="items"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.jobId }}</td>
            <td>{{ props.item.assignmentId }}</td>
            <td>{{ getTime(props.item.startDate) }}</td>
            <td>
              <v-flex>
                {{
                  getLocation(props.item.sourceLocation, props.item.sourceCity)
                }}
              </v-flex>
            </td>
            <td>
              <v-flex>
                {{
                  getLocation(
                    props.item.destinationLocation,
                    props.item.destinationCity
                  )
                }}
              </v-flex>
            </td>
            <td>
              {{
                props.item.paymentAmount
                  ? props.item.paymentAmount + " " + props.item.paymentCurrency
                  : "-"
              }}
            </td>
            <td>
              {{
                 props.item.additionalCharges?props.item.additionalCharges + " " + props.item.paymentCurrency:0 +' '+props.item.paymentCurrency
              }}
            </td>
            <td>
              {{
                props.item.totalAdvancePaidAmount
                  ? props.item.totalAdvancePaidAmount +
                    " " +
                    props.item.paymentCurrency
                  : "-"
              }}
            </td>
            <td>{{ props.item.totalOutStandingAmount || "-" }}</td>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
          >
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
        <span class="page-count-span totalpages"
          >Total Pages - {{ totalPages }}</span
        >
        <PaginationButtons
          :url="`/dmsAdmin/driverAssignment`"
          :callback="callback"
          :payload="payload"
          v-on:pagination-payload="paginationPayload"
        />
      </v-card-text>
    </v-card>

    <div v-if="selectedAssignment">
      <payment-dialog
        :show="paymentDialog"
        :onClose="() => (paymentDialog = false)"
        :assignmentId="selectedAssignment.assignmentId"
        :onConfirm="makePayment"
        :paymentAmount="selectedAssignment.paymentAmount"
        :paymentCurrency="selectedAssignment.paymentCurrency"
      />
    </div>
    <success-dialog
      content="Payment successful"
      :show="showPaymentSuccess"
      :onclose="closeSuccessDialog"
    />
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { transporterDriverEarningList } from "@/constants/datatable-headers.js";
import JobsProfile from "@/components/Jobs/JobDetails/JobsProfile";
import DriverProfileForm from "@/components/Drivers/DriverProfileForm";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import PaymentDialog from "@/components/Pop-ups/PaymentDialog";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { EventBus } from "../../event-bus.js";

export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.countrySortName = key.value;
        }
      });
    }
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "transporter-driver-detail-earnings") {
        this.$router.push(`/transporter`);
      }
      this.countrySortName = val.value;
    });
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
    const { id = null } = this.$route.params;
    this.id = id;
  },
  components: {
    DriverProfileForm,
    JobsProfile,
    PaginationButtons,
    SuccessDialog,
    PaymentDialog,
  },
  data() {
    return {
      id: null,
      search: "",
      totalPages: 0,
      countrySortName: "",
      paymentDialog: false,
      showPaymentSuccess: false,
      loading: false,
      payload: {
        skip: "0",
        limit: "15",
        driverId: this.$route.params.id,
        transporterId: this.$route.params._id,
      },
      headers: transporterDriverEarningList,
      items: [],
      isNoSearchResult: false,
      loading: false,
      paymentError: null,
      selectedAssignment: null,
    };
  },
  methods: {
    paginationPayload(event) {
      this.loading = event;
      this.payload = event;
      localStorage.removeItem("current-customer-page-status");
    },
    performSearch() {
      if (this.search) {
        this.getJobById(this.search);
      }
    },
    goToProfile(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Driver Not Assigned";
        case 2:
          return "Driver Assigned";
        case 3:
          return "Payment Pending";
        case 4:
          return "Payment Done";
        case 5:
          return "In-Transit";
        case 6:
          return "Shipment Offloaded ";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    getJobById(jobId) {
      this.loading = true;
      const url = `${this.constants.serverUrl}/api/jobs/detail/${jobId}`;
      this.axios.get(url).then(
        (response) => {
          const { status, nextURL = null, data } = response.data;
          const { jobInfo = null, totalJobs = 0 } = data;
          this.items = [jobInfo];
          this.loading = false;
          this.totalJobs = totalJobs;
        },
        (error) => {
          this.loading = false;
        }
      );
    },

    getLocation(...val) {
      return val
        ? val
            .filter((val) => val !== undefined)
            .reduce((val, curr) => val + ", " + curr)
        : "";
    },
    callback(res) {
      this.loading = false;
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
    },
    setAssignment(assignment) {
      this.selectedAssignment = assignment;
      this.paymentDialog = true;
    },
    makePayment() {
      if (!this.selectedAssignment) {
        return;
      }
      const { assignmentId } = this.selectedAssignment;
      this.paymentDialog = false;
      this.showPaymentSuccess = true;
      const index = this.items.findIndex(
        (item) => item.assignmentId === assignmentId
      );
      if (index > -1) {
        this.items[index].assignmentStatus = 4;
      }
    },
    closeSuccessDialog() {
      this.showPaymentSuccess = false;
    },
  },
};
</script>

<style lang="scss">
#paymentsTable th {
  background: orange !important;
  font-weight: bold;
}
.table-header-item1 {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.84);
  font-weight: bold;
}
</style>
